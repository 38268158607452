import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Grid from '../../components/common/grid'

import { getGameTransactions } from '../../adapters/transactions'
import storageService from '../../services/storageService'
import media from '../../components/css/media'
import CardGrid from '../../components/common/cardGrid'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import FormatAmount from '../../components/common/formatAmount'

dayjs.extend(utc)

const DateWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
    width: 60%;
    gap: 20px;
    margin: 1.5rem 0;
    align-items: flex-end;
  `};

  input {
    margin: 0 !important;
  }
`

const DateWrapperMobile = styled.div`
  display: block;
  padding: 1rem;

  ${media.tablet`
    display: none;
  `};

  input {
    width: 100% !important;
    margin: 1rem 0 0 0 !important;
  }
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  gap: 20px;

  > div {
    flex: 1 0 calc(50% - 20px);
    width: calc(50% - 20px);
  }
`

const Wrapper = styled.div`
  margin: 0 0 2rem 0;

  input {
    margin: 0 auto;
  }
`

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const GameTransactions = () => {
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  const [startDate, setStartDate] = useState(() => {
    const defaultStart = new Date()
    defaultStart.setDate(defaultStart.getDate() - 10)
    defaultStart.setHours(0)
    defaultStart.setMinutes(0)
    defaultStart.setSeconds(0)
    return defaultStart
  })
  const [endDate, setEndDate] = useState(() => {
    const defaultEnd = new Date()
    defaultEnd.setHours(23)
    defaultEnd.setMinutes(59)
    defaultEnd.setSeconds(59)
    return defaultEnd
  })

  const [page, setPage] = useState(0)
  const [totalTransactions, setTotalTransactions] = useState(0)
  const [transactions, setTransactions] = useState([])

  const columns = [
    {
      columnName: 'transactionID',
      displayName: intl.formatMessage({ id: 'account.transactions.id' }),
      size: 1,
    },
    {
      columnName: 'time',
      displayName: intl.formatMessage({ id: 'account.transactions.date' }),
      size: 3,
    },
    {
      columnName: 'type',
      displayName: intl.formatMessage({ id: 'account.transactions.type' }),
      size: 1,
    },
    {
      columnName: 'game',
      displayName: intl.formatMessage({ id: 'account.transactions.game' }),
      size: 2,
    },
    {
      columnName: 'jackpot',
      displayName: intl.formatMessage({ id: 'account.transactions.jackpot' }),
      size: 1,
    },
    {
      columnName: 'debit',
      displayName: intl.formatMessage({ id: 'account.transactions.bet' }),
      size: 1,
    },
    {
      columnName: 'credit',
      displayName: intl.formatMessage({ id: 'account.transactions.win' }),
      size: 1,
    },
    {
      columnName: 'status',
      displayName: intl.formatMessage({ id: 'account.transactions.status' }),
      size: 2,
    },
  ]

  const handleLoadmore = () => {
    setPage((page) => page + 1)
    fetchTransactions(page + 1, true)
  }

  const handleStartDateChange = (date) => {
    let newDate = new Date(date)
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    setStartDate(newDate)
  }

  const handleEndDateChange = (date) => {
    let newDate = new Date(date)
    newDate.setHours(23)
    newDate.setMinutes(59)
    newDate.setSeconds(59)
    setEndDate(newDate)
  }

  const handleGetTransactions = () => {
    fetchTransactions(0, false)
  }

  const fetchTransactions = useCallback(
    async (currentPage, loadMoreClicked) => {
      const formatTransactionsData = (data) => {
        if (!data) {
          return []
        }

        const user = storageService.getUser()
        let formattedData = []

        data.forEach((row) => {
          let gridRow = {}

          gridRow['transactionID'] = { value: row.id }
          gridRow['time'] = {
            value: dayjs.utc(row.created_at).format('DD.MM.YYYY HH:mm:ss'),
          }
          gridRow['type'] = { value: row.bet_type ? row.bet_type : '-' }
          gridRow['game'] = { value: row.game_code ? row.game_code : '-' }

          gridRow['jackpot'] = {
            value: (
              <FormatAmount
                amount={row.jackpot_amount}
                currency={user.wallet.currency.short_code}
              />
            ),
          }

          if (
            row.bet_type !== undefined &&
            row.bet_type.toLowerCase() === 'bet'
          ) {
            gridRow['debit'] = {
              value: (
                <FormatAmount
                  amount={row.amount}
                  currency={user.wallet.currency.short_code}
                />
              ),
            }
            gridRow['type'] = {
              value: intl.formatMessage({
                id: `account.transactions.types.bet`,
              }),
            }
          } else {
            gridRow['debit'] = { value: '-' }
          }

          if (
            row.bet_type !== undefined &&
            row.bet_type.toLowerCase() !== 'bet'
          ) {
            gridRow['credit'] = {
              value: (
                <FormatAmount
                  amount={row.amount}
                  currency={user.wallet.currency.short_code}
                />
              ),
            }
            gridRow['type'] = {
              value: intl.formatMessage({
                id: `account.transactions.types.winning`,
              }),
            }
          } else {
            gridRow['credit'] = { value: '-' }
          }

          let color = ''
          switch (row.round_status.toLowerCase()) {
            case 'open':
              color = '#FFB300'
              break
            case 'cancelled':
              color = '#FF3C3C'
              break
            case 'closed':
            default:
              color = '#31C27C'
              break
          }

          gridRow['status'] = {
            value: intl.formatMessage({
              id: `account.transactions.types.${row.round_status}`,
            }),
            color,
          }

          formattedData.push(gridRow)
        })

        return formattedData.sort((a, b) => {
          if (b.transactionID.value < a.transactionID.value) {
            return -1
          }
          if (b.transactionID.value > a.transactionID.value) {
            return 1
          }
          return 0
        })
      }

      const response = await getGameTransactions(
        startDate,
        endDate,
        currentPage,
        10
      )

      if (response.ok) {
        if (loadMoreClicked) {
          setTransactions(
            transactions.concat(
              formatTransactionsData(response.data.data.content)
            )
          )
        } else {
          setTransactions(formatTransactionsData(response.data.data.content))
        }
        setTotalTransactions(response.data.data.totalElements)
      }
    },
    [endDate, intl, startDate, transactions]
  )

  useEffect(() => {
    fetchTransactions()
    // eslint-disable-next-line
  }, [])

  return (
    <Wrapper>
      <DateWrapper>
        <Col>
          <label htmlFor='startdate'>
            <FormattedMessage id='common.from' />
          </label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDateChange}
            selectsStart
            id='startdate'
            dateFormat='d MMMM yyyy'
            startDate={startDate}
            endDate={endDate}
          />
        </Col>
        <Col>
          <label htmlFor='enddate'>
            <FormattedMessage id='common.to' />
          </label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            id='enddate'
            dateFormat='d MMMM yyyy'
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </Col>
        <input
          type='button'
          className='secondary'
          onClick={handleGetTransactions}
          value={intl.formatMessage({ id: 'common.show' })}
        />
      </DateWrapper>

      <DateWrapperMobile>
        <SectionHeader>
          <FormattedMessage id='account.transactions.selectDates' />
        </SectionHeader>
        <Row>
          <Col>
            <label htmlFor='from'>
              <FormattedMessage id='common.from' />
            </label>
            <input
              type='date'
              id='from'
              onChange={(e) => handleStartDateChange(e.target.value)}
              defaultValue={dayjs(startDate).format('YYYY-MM-DD')}
              max={endDate}
            />
          </Col>
          <Col>
            <label htmlFor='to'>
              <FormattedMessage id='common.to' />
            </label>
            <input
              type='date'
              id='to'
              onChange={(e) => handleEndDateChange(e.target.value)}
              defaultValue={dayjs(endDate).format('YYYY-MM-DD')}
              min={startDate}
            />
          </Col>
        </Row>
        <input
          type='button'
          className='secondary'
          onClick={handleGetTransactions}
          value={intl.formatMessage({ id: 'common.show' })}
        />
      </DateWrapperMobile>

      {!isMobile && <Grid columns={columns} data={transactions} />}

      {isMobile && <CardGrid columns={columns} data={transactions} />}

      {totalTransactions > transactions.length && (
        <input
          type='button'
          className='secondary'
          onClick={handleLoadmore}
          value={intl.formatMessage({ id: 'common.loadMore' })}
        />
      )}
    </Wrapper>
  )
}

export default GameTransactions
