import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'

import 'react-datepicker/dist/react-datepicker.css'

import AccountWrapper from './wrapper'
import DepositTransactions from './depositTransactions'

import media, { sizes } from '../../components/css/media'
import WithdrawTransactions from './withdrawTransactions'
import SportsTransactions from './sportsTransactions'
import GameTransactions from './gameTransactions'
import NetDepositsTransactions from './netDepositsTransactions'
import useModal from '../../hooks/useModal'
import Modal from '../../components/common/modal'

import ChevronLeftIcon from '../../images/icon_chevron_left_white.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Title = styled.div`
  text-align: center;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  line-height: 50px;
  padding: 0 1rem;

  > img {
    float: left;
    margin: 0.9rem 0 0 0;

    &:last-child {
      float: right;
    }
  }

  ${media.tablet`
    font-size: 1.5em;
    font-weight: 600;
    color: #000;
    line-height: initial;
    padding: 0;
    background-color: #fff;
    text-align: left;

    > img {
      display: none;
    }
  `};
`

const Tabs = styled.div`
  justify-content: center;
  display: flex;
`

const Ul = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;

  ${media.tablet`
    width: auto;
  `};
`

const Li = styled.li`
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem 0;
  width: 33.33%;
  text-align: center;
  font-size: 0.9em;
  flex: 1;
  min-width: 100px;

  &:hover,
  &.active {
    border-bottom: 3px solid #da0000;
    padding-bottom: calc(1rem - 3px);
    color: #da0000;
  }

  ${media.tablet`
    margin: 0 .5rem;
    padding: 1rem;
    font-size: 1em;
    width: auto;
    flex: auto;
    min-width: initial;

    &:hover,
    &.active {
      color: #000;
    }
  `};
`

const Transactions = () => {
  const breakpoint = sizes.tablet
  const { closeModal } = useModal()
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  const handleClose = (action) => {
    closeModal()
    action === 'close' ? navigate('/casino') : navigate('/account')
  }

  return (
    <AccountWrapper>
      {width < breakpoint && (
        <Modal
          theme='account'
          title={''}
          isOpen={width < breakpoint}
          onClose={handleClose}
        >
          <TransactionsContent closeModal={handleClose} />
        </Modal>
      )}

      {width > breakpoint && <TransactionsContent />}
    </AccountWrapper>
  )
}

const TransactionsContent = ({ closeModal }) => {
  const [tab, setTab] = useState(0)

  return (
    <>
      <Title>
        <img
          src={ChevronLeftIcon}
          alt='Left'
          width={12}
          onClick={() => closeModal('back')}
        />
        <FormattedMessage id='account.transactions.header' />
        <img
          src={TimesIcon}
          alt='Close'
          width={12}
          onClick={() => closeModal('close')}
        />
      </Title>
      <Tabs>
        <Ul>
          <Li className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
            <FormattedMessage id='account.transactions.deposits' />
          </Li>
          <Li className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
            <FormattedMessage id='account.transactions.withdrawals' />
          </Li>
          <Li className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
            <FormattedMessage id='account.transactions.sports' />
          </Li>
          <Li className={tab === 3 ? 'active' : ''} onClick={() => setTab(3)}>
            <FormattedMessage id='account.transactions.gameplay' />
          </Li>
          <Li className={tab === 4 ? 'active' : ''} onClick={() => setTab(4)}>
            <FormattedMessage id='account.transactions.netDeposits' />
          </Li>
        </Ul>
      </Tabs>

      {tab === 0 && <DepositTransactions />}
      {tab === 1 && <WithdrawTransactions />}
      {tab === 2 && <SportsTransactions />}
      {tab === 3 && <GameTransactions />}
      {tab === 4 && <NetDepositsTransactions />}
    </>
  )
}

export default Transactions
