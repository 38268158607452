import React, { useEffect, useState, useCallback } from 'react'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import * as dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import Grid from '../../components/common/grid'

import { getNetDeposits } from '../../adapters/transactions'
import storageService from '../../services/storageService'
import CardGrid from '../../components/common/cardGrid'
import useDeviceDetect from '../../hooks/useDeviceDetect'
import FormatAmount from '../../components/common/formatAmount'

dayjs.extend(utc)

const Wrapper = styled.div`
  margin: 2rem 0;

  input {
    margin: 0 auto;
  }
`

const NetDepositsTransactions = () => {
  const intl = useIntl()
  const { isMobile } = useDeviceDetect()

  const [transactions, setTransactions] = useState([])

  const columns = [
    {
      columnName: 'totalDeposits',
      displayName: intl.formatMessage({
        id: 'account.transactions.totalDeposits',
      }),
      size: 4,
    },
    {
      columnName: 'totalWithdraws',
      displayName: intl.formatMessage({
        id: 'account.transactions.totalWithdraws',
      }),
      size: 4,
    },
    {
      columnName: 'netTotal',
      displayName: intl.formatMessage({ id: 'account.transactions.netTotal' }),
      size: 4,
    },
  ]

  const fetchTransactions = useCallback(async () => {
    const user = storageService.getUser()

    const formatTransactionsData = (data) => {
      if (!data) {
        return []
      }

      let formattedData = []

      data.forEach((row) => {
        let gridRow = {}

        gridRow['totalDeposits'] = {
          value: (
            <FormatAmount
              amount={row.totalDeposits}
              currency={user.wallet.currency.short_code}
            />
          ),
        }
        gridRow['totalWithdraws'] = {
          value: (
            <FormatAmount
              amount={row.totalWithdraws}
              currency={user.wallet.currency.short_code}
            />
          ),
        }

        gridRow['netTotal'] = {
          value: (
            <FormatAmount
              amount={row.netTotal}
              currency={user.wallet.currency.short_code}
            />
          ),
          color: row.netTotal >= 0 ? '#31C27C' : '#FF3C3C',
        }

        formattedData.push(gridRow)
      })

      return formattedData
    }

    const response = await getNetDeposits(user.created_at, new Date())
    if (response.ok) {
      setTransactions(
        formatTransactionsData([
          {
            totalDeposits: response.data.data.total_deposit_amount,
            totalWithdraws: response.data.data.total_withdrawal_amount,
            netTotal:
              response.data.data.total_withdrawal_amount -
              response.data.data.total_deposit_amount,
          },
        ])
      )
    }
  }, [])

  useEffect(() => {
    fetchTransactions()
  }, [fetchTransactions])

  return (
    <Wrapper>
      {!isMobile && <Grid columns={columns} data={transactions} />}

      {isMobile && <CardGrid columns={columns} data={transactions} />}
    </Wrapper>
  )
}

export default NetDepositsTransactions
